import React from "react"
import { Link } from "gatsby"
import Contacts from "../components/contacts"
import Seo from "../components/seo"
import Container from "../components/shared/policies/termsContainer"
import Text from "../components/shared/policies/textContainer"
import Title from "../components/shared/policies/title"
import Subtitles from "../components/shared/policies/subtitles"
import Paragraphs from "../components/shared/policies/paragraphs"
import NewFooter from "../components/shared/NewFooter"

function Licensing() {
  return (
    <>
      <Container>
        <Seo title="EULA" />
        <Title>PDF Pro END USER LICENSE AGREEMENT</Title>
        <Text>
          <Paragraphs>
            <i>Last update: October 17th, 2021</i>
          </Paragraphs>
          <Paragraphs>
            This end user license agreement (hereinafter referred to as the “
            <strong>EULA</strong>") constitute a legal and binding agreement
            between PDF Pro Software Ltd., with registered office at 1383 W 8th
            Ave, Vancouver, BC V6H 3V9, Canada, business registration no.
            700468291BC0001, including its subsidiaries, affiliates and
            contractors acting on its behalf (hereinafter collectively referred
            to as “<strong>PDF Pro</strong>”, “<strong>Us</strong>”, “
            <strong>We</strong>”, or “<strong>Our</strong>”) and any person or
            entity and its heirs, agents, successors and assigns that have
            purchased, or is authorized to, access Our PDF Pro Software
            (hereinafter singularly and collectively referred to as “
            <strong>You</strong>” or “<strong>Your</strong>”)
          </Paragraphs>
          <Paragraphs>
            <strong>
              This EULA includes and incorporates by reference the PDF Pro Terms
              of Service (available at <Link to="/terms">Terms of Service</Link>{" "}
              and the PDF Privacy Policy (available at{" "}
              <Link to="/privacy">Privacy Policy</Link> ).
            </strong>
          </Paragraphs>
          <Paragraphs>
            <strong>
              All terms with initial capital letters used herein shall have the
              meanings ascribed to them in the PDF Pro Terms of Service, unless
              specifically defined herein.
            </strong>
          </Paragraphs>
          <Paragraphs>
            <strong>
              In case You are an Authorized User, the terms of this EULA and the
              PDF Pro Terms of Services shall apply in full, exception made for
              the payment obligations, which are in charge to the entity that
              purchased the relevant Subscription Plan with a Multi-User
              Account.
            </strong>
          </Paragraphs>
          <Paragraphs>
            <strong>
              IMPORTANT NOTICE: Your continued use of the PDF Pro Software
              constitutes Your expressed acceptance of, and agreement to be
              bound by, this EULA, as in force from time to time. Therefore, You
              understand and acknowledge that by downloading, accessing,
              installing or using the PDF Pro Software You agree to be bound by
              the terms of this EULA. If You do not accept such terms in their
              entirety, You must not download, access, install or use the PDF
              Pro Software. If You agree to this EULA on behalf of an entity, or
              in connection with providing or receiving services on behalf of an
              entity, You represent and warrant that You have the authority to
              bind that entity to this EULA. In such an event, “You” and “Your”
              will refer and apply to that entity or agency
            </strong>
          </Paragraphs>
          <Subtitles>
            SECT. 1 – SOURCES, ACCESS, DELIVERY AND REQUIREMENTS
          </Subtitles>
          <Paragraphs>
            <strong>1.1) Authorized sources</strong>. You must lawfully acquire
            the PDF Pro Software by purchasing, downloading or accessing it from
            Our website at <Link to="/purchased">Purchased</Link> or from any of
            Our authorized reseller, partner or provider. In the latter case,
            You shall also comply with the terms and conditions of such PDF
            Pro’s resellers, partners or providers.
          </Paragraphs>
          <Paragraphs>
            <strong>1.2) Access</strong>. Your access to the PDF Pro Software is
            granted subject to an account registration to Our website platform
            at <Link to="/register">Register</Link> , as well as under the terms
            of this EULA, the PDF Pro Terms of Service and the relevant
            Subscription Plan, all of which shall be incorporated herein by
            reference.
          </Paragraphs>
          <Paragraphs>
            <strong>1.3) Delivery</strong>. The PDF Pro Software shall be deemed
            delivered on the date that We make it available to You, either with
            a direct download link or by creating a user account for Your access
            thereto.
          </Paragraphs>
          <Paragraphs>
            <strong>1.4) Requirements</strong>. Only the software running on the
            hardware and in the environment specified in the PDF Pro Software’s
            documentation is unconditionally eligible for running the same, as
            well as for Our support and other services. You agree that You are
            solely responsible for these requirements, as well as for any needed
            changes, updates and fees.
          </Paragraphs>
          <Subtitles>SECT. 2 – RIGHTS GRANTED</Subtitles>
          <Paragraphs>
            {" "}
            <strong> 2.1) Grant of license</strong>. Subject to the relevant
            Subscription Plan, You are granted a limited, worldwide,
            non-assignable, non-sublicensable, non-transferable and
            non-exclusive license to use the PDF Pro Software according to the
            purchased Subscription Plan.{" "}
            <strong>
              The access to Services as part of the PDF Pro Software is also
              subject to the terms and conditions of the PDF Pro Terms of
              Service, which shall be incorporated herein by reference.
            </strong>
          </Paragraphs>
          <Paragraphs>
            <strong>2.2) Allowed devices</strong>. You are permitted to access
            and use the PDF Pro Software only by running the number of devices
            (and/or Authorized Users, in case of Multi-User Account) allowed by
            the relevant Subscription Plan. If not otherwise specified, the
            maximum devices allowed per user (and/or per Authorized User, in
            case of Multi-User Account) are two (2).
          </Paragraphs>
          <Paragraphs>
            <strong>2.3) Scope of the license</strong>. You agree that You will
            use the PDF Pro Software only in connection with Your own normal
            internal commercial use, and You will not – unless otherwise
            provided in this EULA or by the applicable Subscription Plan and/or
            authorized in writing by Us – sell, lease, re-distribute, provide
            managed service, or otherwise provide or make available, either
            directly or via other means, or otherwise, the PDF Pro Software to
            any third party or use it for the benefit of any third party.
          </Paragraphs>
          <Paragraphs>
            <strong>2.4) Third-Party Software</strong>. You expressly
            acknowledge and accept that certain content, products and Services
            available via the PDF Pro Software may include Third-Party Software,
            as specified in PDF Pro Software’s documentation. All Third-Party
            Software is licensed to You under the terms of this EULA, unless We
            provide other terms along with the PDF Pro Software’s documentation.
            To any Third-Party Software shall apply Sects. 8.1), 8.2), 8.3) and
            8.4) below and, therefore, without limiting the foregoing, We are
            not responsible for examining or evaluating the content or accuracy
            of the Third-Party Software nor provide any warranty, express or
            implied, with respect to any Third-Party Software and expressly
            disclaim any warranty or condition of merchantability, fitness for a
            particular purpose, title, and non-infringement.
          </Paragraphs>
          <Paragraphs>
            <strong>2.5) Reservation of rights</strong>. We expressly reserve
            all rights not expressly granted to You in this EULA.
          </Paragraphs>
          <Subtitles>SECT. 3 – YOUR RESPONSIBILITIES</Subtitles>
          <Paragraphs>
            <strong>3.1) General responsibilities</strong>. You are entirely
            responsible for the use of the PDF Pro Software and, to such
            purpose, You shall not use, encourage, promote, facilitate, instruct
            (or induce others to do the foregoing on) the PDF Pro Software for
            any activity that violates any applicable Law, or for any other
            illegal, fraudulent, harmful, or offensive purpose, or to transmit,
            store, display, distribute or otherwise make available any Data that
            is illegal, harmful, offensive, libelous, defamatory, obscene,
            pornographic, profane, threatening, abusive, hateful, harassing,
            inappropriate or that would encourage or constitute a criminal or
            civil liability under any Law. Examples of prohibited uses of the
            PDF Pro Software include: (<strong>i</strong>) creating, uploading,
            modifying and/or distributing any Data, or take any action using the
            PDF Pro Software, that is illegal, fraudulent, harmful, or violates
            any applicable Law; (<strong>ii</strong>) creating, uploading,
            modifying and/or distributing any Data, or take any action using the
            PDF Pro Software that violates any PDF Pro’s right or any third
            party’s (including, without limitations, privacy, Intellectual
            Property Rights, etc.); (<strong>iii</strong>) uploading viruses or
            malicious codes, or take any other action using the PDF Pro Software
            that could in any way compromise any functionality thereof; (
            <strong>iv</strong>) distributing the PDF Pro Software; (
            <strong>v</strong>) incorporating the PDF Pro Software in any
            product designed, developed, marketed, shared, sold or licensed by
            You or any third party; (<strong>vi</strong>) facilitating or
            encouraging any violations of this EULA.
          </Paragraphs>
          <Paragraphs>
            <strong>3.2) Data lawfulness</strong>. You undertake to have all the
            interest and title (including, without limitation, any related
            Intellectual Property Right) in and to the Data that You may
            transmit, store, display, distribute or otherwise make available
            through the PDF Pro Software, and that You are the sole responsible
            for the accuracy, quality, integrity, legality, reliability, and
            appropriateness thereof. Without limiting the foregoing, You warrant
            and represent that such Data is fully compliant with any applicable
            Law. In no way whatsoever We will be responsible for any of such
            Data.
          </Paragraphs>
          <Paragraphs>
            <strong>3.3) Updates</strong>. In case the PDF Pro Software needs
            any update as provided in Sect. 4.1) below, You expressly agree to
            install such updates as soon they are available, bearing any and all
            the related costs, if any. You shall bear full risk of any loss or
            damage in case any updates are not properly and timely installed due
            to Your fault; We expressly disclaim any liability in such respect.
          </Paragraphs>
          <Paragraphs>
            <strong>3.4) Export regulations compliance</strong>. You expressly
            acknowledge that You are not an entity targeted by any Law which
            provides economic sanctions, export controls, import regulations or
            trade embargoes, nor You are otherwise owned or controlled by, or
            acting on behalf of, any person or entity targeted or in any way
            affected by the foregoing. Furthermore, You expressly acknowledge
            that You will not download or otherwise export or re-export the PDF
            Pro Software’s content, products or services or any related
            technical data directly or indirectly to any person or entity
            targeted by the aforementioned Law or for any end-use prohibited or
            restricted by such Law.
          </Paragraphs>
          <Paragraphs>
            <strong>3.5) Indemnification</strong>. To any third party’s claim
            arising out of a violation of Your obligations under this Section 3
            (Your Responsibilities), Sects. 9.1) and 9.2) below shall apply, in
            addition to any other rights or remedies afforded to Us under this
            EULA and/or as per the applicable Law.
          </Paragraphs>
          <Subtitles>
            SECT. 4 – UPDATES, AVAILABILITY AND DISCONTINUATION
          </Subtitles>
          <Paragraphs>
            <strong>4.1) Updates</strong>. We, in Our sole discretion, reserve
            the right to add or remove tools, utilities, improvements,
            Third-Party Software, features or functions, or to provide
            programming fixes, updates and upgrades, to the PDF Pro Software in
            order to improve and enhance the features and performance thereof
            and/or according to Our discontinuation policy. To such purpose, You
            expressly acknowledge and agree that: (<strong>i</strong>) We have
            no obligation to make available and/or provide any updates; (
            <strong>ii</strong>) in case We provide any updates, this EULA shall
            automatically apply thereto, unless We provide other terms along
            with such updates; (<strong>iii</strong>) the PDF Pro Software may
            contain automatic update technology, a feature used to provide
            maintenance (such as a bug fix, patches, enhanced functions, missing
            plug-ins, and new versions) and, by installing the proper software
            initially, You consent to the transmission of standard computer
            information and the automatic downloading and installation of
            maintenance software; (<strong>iv</strong>) We disclaim any
            liability in case any updates result in an unavailability,
            deficiency or incompatibility of any Third-Party Software (and/or of
            any other pre-existing feature or function) with the updated PDF Pro
            Software.
          </Paragraphs>
          <Paragraphs>
            <strong>4.2) Availability</strong>. We will use commercially
            reasonable efforts to ensure the availability of the PDF Pro
            Software for Our customers. Regardless, You acknowledge and agree
            that We have no control or liability over the availability of the
            PDF Pro Software on a continuous or uninterrupted basis, so that
            from time to time the same may be inaccessible or inoperable,
            including, without limitation, for: (<strong>i</strong>) equipment
            malfunctions, periodic maintenance procedures or repairs; (
            <strong>ii</strong>) Force Majeure or any other cause beyond Our
            reasonable control or that are not reasonably foreseeable
            (including, without limitation, threat to security, interruption or
            failure of telecommunication or digital transmission links, hostile
            network attacks, network congestion or other failures); (
            <strong>iii</strong>) limitations due to Your particular device or
            hardware; (<strong>iv</strong>) unavailability, deficiency or
            incompatibility of any Third-Party Software with the PDF Pro
            Software for any reason (e.g., third-party’s or PDF Pro’s
            discontinuation policy, incompatibilities between any updates and a
            Third-Party Software, etc.); (<strong>v</strong>) Your violation of
            any relevant provision of this EULA. Any unavailability of the
            Software under this Sect. 4.2) shall not excuse You from Your
            obligation to make timely payment(s) under the applicable
            Subscription Plan.
          </Paragraphs>
          <Paragraphs>
            <strong>4.3) Discontinuation policy</strong>. You expressly
            acknowledge and accept that the PDF Pro Software is subject to Our
            discontinuation policy and, therefore, We reserve the right –
            without Our liability in such respect towards You or any other third
            party – to discontinue any PDF Pro Software’s feature, content or
            service (including, without limitation, access to technical support,
            access to Third-Party Software, etc.), in accordance with such
            discontinuation policy. The preceding applies also to any
            Third-Party Software, which is subject to such third party’s
            discontinuation policy, without Our liability towards You or any
            other third party in case any updates result in an unavailability,
            deficiency or incompatibility of any pre-existing Third-Party
            Software with the updated Software.
          </Paragraphs>
          <Subtitles>SECT. 5 – TERM AND TERMINATION</Subtitles>
          <Paragraphs>
            <strong>5.1) Term</strong>. The rights herein conferred to You are
            provided as of the Effective Date and for the entire term of the
            relevant Subscription Plan.
          </Paragraphs>
          <Paragraphs>
            <strong>5.2) Termination</strong>. In case of Your breach of any
            term herein provided not cured within 10 (ten) days after Our
            initial notice thereof, We are entitled to terminate the EULA and/or
            the relevant Subscription Plan.
          </Paragraphs>
          <Paragraphs>
            <strong>5.3) No access after termination</strong>. Upon the
            termination, for any reason whatsoever, of this EULA, You no longer
            have rights to access or use the PDF Pro Software and, if
            applicable, We will delete or otherwise make inaccessible Your
            account and any Data in the PDF Pro Software, if any. We shall not
            be liable towards You or any other third party for the loss of any
            Data and/or customization after the termination of this EULA for
            whatever reason or cause.
          </Paragraphs>
          <Paragraphs>
            <strong>5.4) Obligations surviving termination</strong>. Provisions
            that survive termination or expiration of this EULA are those
            relating to limitation of liability, indemnification, Intellectual
            Property Rights and others which by their nature are intended to
            survive.
          </Paragraphs>
          <Subtitles>SECT. 6 – INTELLECTUAL PROPERTY RIGHTS</Subtitles>
          <Paragraphs>
            <strong>6.1) PDF Pro ownership</strong>. We hold and retain all an
            any title and interest in and to the PDF Pro Software, as well as in
            any Intellectual Property Right associated with the same and,
            therefore, nothing in this EULA will be interpreted as a transfer,
            in whole or in part, of any IP Rights in and to the PDF Pro Software
            to You or any other third party.
          </Paragraphs>
          <Paragraphs>
            <strong>
              6.2) Your obligations for Our Intellectual Property Rights
              preservation
            </strong>
            . You must not, and must not cause or permit others to: (
            <strong>i</strong>) remove or alter any PDF Pro Software
            identification code, marking or notice of PDF Pro or its licensors;
            (<strong>ii</strong>) make the PDF Pro Software available in any
            manner to any third party for use in such third party’s business
            operations; (<strong>iii</strong>) copy, modify, adapt, make
            derivative works of, disassemble, decompile, reverse engineer,
            reproduce, distribute, republish or download any part of the PDF Pro
            Software, or access or use the PDF Pro Software in order to build or
            support, or assist a third party in building or supporting, products
            or services competing with the PDF Pro Software; (
            <strong>iv</strong>) perform or disclose any benchmark or
            performance tests of the PDF Pro Software or any associated
            infrastructure (such as, without limitation, network discovery, port
            and service identification, vulnerability scanning, password
            cracking, remote access testing, penetration testing, etc.); and (
            <strong>v</strong>) license, sell, rent, lease, transfer, assign,
            distribute, host, outsource, permit timesharing or service bureau
            use, or otherwise commercially exploit or make available the PDF Pro
            Software to any third party.
          </Paragraphs>
          <Paragraphs>
            <strong>6.3) Your Data</strong>. You expressly grant Us a perpetual,
            worldwide, fully transferable, sub-licensable, irrevocable, fully
            paid-up, royalty free license to perform any operation or set of
            operations (including, without limitation, use, modify, collect,
            record, organize, structure, store, adapt, alter, retrieve, consult,
            use, disclose, combine, reproduce, erase, destroy, etc.) on the Data
            that You provide to Us in any way (by upload or other mean), as far
            as necessary for the purposes of providing You access to the PDF Pro
            Software and improve the functionalities thereof.
          </Paragraphs>
          <Subtitles>SECT. 7 – PRIVACY AND DATA PROTECTION</Subtitles>
          <Paragraphs>
            <strong>7.1) Privacy Policy</strong>. You declare to have carefully
            read the PDF Pro Privacy Policy (available at{" "}
            <Link to="/privacy">Privacy Policy</Link> ) and got a clear
            understanding of how We collect, use, protect or otherwise handle
            Personal Data.
          </Paragraphs>
          <Paragraphs>
            <strong>7.2) Our right to access Data</strong>. To the maximum
            extent permitted by the applicable Law, We expressly reserves the
            right to access, read, preserve, and disclose any information as we
            may reasonably believe necessary to: (<strong>i</strong>) satisfy
            any applicable Law or any authority request; (<strong>ii</strong>)
            enforce this EULA (including investigation of potential violations
            thereof) or Our Intellectual Property Rights; (<strong>iii</strong>)
            detect, prevent, or otherwise address fraud, anti-piracy, security
            or technical issues (including, without limitation, verify a valid
            registration, the number of instances the PDF Pro Software launches,
            the device IP address, and/or the version of the PDF Pro Software,
            etc.); (<strong>iv</strong>) conduct internal statistics and
            analytics, on an anonymized basis; in particular, We may trace the
            use of the PDF Pro Software for the purpose of obtaining statistics
            on the use and proper functioning thereof, as well as for the
            purpose of correctly provide functionalities and improvements, or
            for security reasons.
          </Paragraphs>
          <Subtitles>SECT. 8 – WARRANTY AND LIABILITY</Subtitles>
          <Paragraphs>
            <strong>8.1) Limited warranty</strong>. THE PDF PRO SOFTWARE IS
            PROVIDED “AS IS”, “AS AVAILABLE” AND “WITH ALL FAULTS”, WITHOUT
            WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY THE
            APPLICABLE LAW, WE DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS
            OR IMPLIED, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
            AND ANY WARRANTY THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF
            PERFORMANCE, USAGE OR TRADE PRACTICE. TO THE FULLEST EXTENT
            PERMITTED OR AUTHORIZED BY LAW AND WITHOUT LIMITATION OF THE
            FOREGOING, WE DO NOT WARRANT THAT THE PDF PRO SOFTWARE WILL MEET
            YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR
            WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, BE
            AVAILABLE OR OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR
            RELIABILITY STANDARDS OR THAT THE USE OF THE PDF PRO SOFTWARE WILL
            BE UNINTERRUPTED OR ERROR-FREE OR THAT ANY ERRORS OR DEFECTS CAN OR
            WILL BE CORRECTED.{" "}
            <strong>
              THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF
              THIS EULA.
            </strong>
          </Paragraphs>
          <Paragraphs>
            <strong>8.2) Disclaimer of liability</strong>. IN NO EVENT SHALL WE
            BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY,
            PUNITIVE, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO,
            PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, COMPUTER FAILURE OR
            MALFUNCTION, LOSS OF DATA OR PROFITS, BUSINESS INTERRUPTION, ETC.)
            HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT,
            STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE)
            ARISING IN ANY WAY OUT OF THE USE OF THE PDF PRO SOFTWARE, EVEN IF
            WE ARE AWARE OF OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGE. IN ANY CASE, WE ARE NOT LIABLE FOR THE ACTS OR OMISSIONS OF
            OUR CONTRACTORS, VENDORS, CLOUD PROVIDER OR OTHER SERVICE PROVIDER.{" "}
            <strong>
              THE FOREGOING EXCLUSIONS AND DISCLAIMERS ARE AN ESSENTIAL PART OF
              THIS EULA.
            </strong>
          </Paragraphs>
          <Paragraphs>
            <strong>8.3) Remedies</strong>. EXCEPT FOR CLAIMS WITH RESPECT TO
            FRAUD, DEATH OR PERSONAL INJURY, FOR WHICH NO LIMIT SHALL APPLY, IN
            NO EVENT – TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE LAW –
            OUR LIABILITY SHALL IN THE AGGREGATE EXCEED THE FEES EFFECTIVELY
            PAID THE RELEVANT SUBSCRIPTION PLAN, DURING THE 12 (TWELVE) MONTH
            PERIOD PRECEDING THE DATE ON WHICH THE CLAIM AROSE. Notwithstanding
            the preceding, in case the PDF Pro Software infringes or
            misappropriates any third party’s Intellectual Property Rights and
            We believe – in Our sole discretion – that this is likely to result
            in an adverse ruling for Us, then We will at Our sole discretion: (
            <strong>i</strong>) modify the PDF Pro Software to be
            non-infringing; (<strong>ii</strong>) replace the PDF Pro Software
            with non-infringing substitute updates; or (<strong>iii</strong>)
            terminate this EULA and provide a pro-rata refund of the fees
            actually paid by You for the allegedly infringing software. In the
            cases (<strong>ii</strong>) and (<strong>ii</strong>) mentioned
            above, You shall install the new PDF Pro Software or the updates as
            soon they are available.{" "}
            <strong>
              THE FOREGOING CONSTITUTES YOUR SOLE REMEDIES AVAILABLE TO YOU WITH
              RESPECT TO ANY OF OUR LIABILITY UNDER THIS EULA.
            </strong>
          </Paragraphs>
          <Paragraphs>
            <strong>8.4) Mandatory legal provisions</strong>. Some jurisdictions
            do not allow certain limitations of warranty or liability, therefore
            some or all of the limitations above provided may not apply to You.
            In such case, the limitations provided herein shall be applicable to
            the fullest and maximum extent permitted by the applicable Law.
          </Paragraphs>
          <Subtitles>SECT. 9 – INDEMNIFICATION</Subtitles>
          <Paragraphs>
            <strong>9.1) Indemnification</strong>. You shall indemnify and hold
            Us harmless from and against all damages, losses, and expenses of
            any kind (including reasonable legal fees and costs) arose by any
            claim made by any third party (including, without limitation, any
            agency, public administrations, entities, etc.) against Us in
            connection with any of Your: (<strong>i</strong>) violation or
            breach of any term of this EULA; (<strong>ii</strong>) violation of
            any applicable Law, whether or not referenced herein; (
            <strong>iii</strong>) violation of any rights of any third party; (
            <strong>iv</strong>) use or misuse of the PDF Pro Software.
          </Paragraphs>
          <Paragraphs>
            <strong>9.2) Remedies</strong>. You expressly agree and acknowledge
            that any violation of Your obligations pursuant to this EULA may
            cause Us irreparable harm and damage, which may not be recovered at
            law. Therefore, You agree that Our remedies for breach of this EULA
            may be in equity by way of injunctive relief, as well and any other
            relief available, whether in law or in equity.
          </Paragraphs>
          <Subtitles>SECT. 10 – MISCELLANEOUS</Subtitles>
          <Paragraphs>
            <strong>10.1) Assignment</strong>. You are not allowed to assign
            this EULA or any rights hereunder, unless with Our previous written
            consent. Conversely, We are allowed, at Our sole discretion, to
            assign this EULA or any rights hereunder to any third party, without
            giving You any prior notice.
          </Paragraphs>
          <Paragraphs>
            <strong>10.2) Force majeure</strong>. A Party shall not be
            responsible for any failure to perform due to any event of Force
            Majeure. In the event of any such delay, the affected Party will be
            excused from such performance to the extent it is delayed or
            prevented by such cause. However, the other Party may terminate this
            EULA forthwith on written notice if such Force Majeure condition
            continues for a period of sixty (60) days.
          </Paragraphs>
          <Paragraphs>
            <strong>10.3) No implied waiver</strong>. Any tolerance or silence
            by Us, even if on a continuous and reiterated basis, in relation to
            any breach or default by You of any provision of this EULA shall not
            be considered as a consent to such breaches and defaults and shall
            not affect the validity of the clause breached nor shall be
            construed as a waiver to any of the rights pertaining to Us pursuant
            to this EULA and/or the applicable Law.
          </Paragraphs>
          <Paragraphs>
            <strong>10.4) Governing law and exclusive jurisdiction</strong>.
            This EULA is governed by the substantive and procedural Laws of
            British Columbia, Canada, without application of the “United Nations
            Convention on Contracts for the International Sale of Goods”. Any
            dispute arising out of, or relating to, this EULA shall be submitted
            to the exclusive jurisdiction of the Court of Vancouver (Canada).
          </Paragraphs>
          <Paragraphs>
            <strong>10.5) Prevailing language</strong>. Any translation of this
            EULA is provided solely for your convenience and is not intended to
            modify the terms herein. In the event of a conflict between the
            English version of this EULA and a version in a language other than
            English, the English version shall control and prevail
          </Paragraphs>
          <Paragraphs>
            <strong>10.6) Severability</strong>. Should any part of this EULA be
            declared illegal or unenforceable, the remaining portion will remain
            in full force and effects.
          </Paragraphs>
          <Paragraphs>
            <strong>10.7) Amendments</strong>. The effective date of the last
            version of this EULA is written above, as published from time to
            time on Our website at <Link to="/licensing">EULA</Link>. We have
            the right to amend this EULA at any time, and to change, delete,
            discontinue or impose conditions on use of the PDF Pro Software, in
            which case the new terms and conditions will supersede prior terms.
            Any changes will be effective after thirty (30) days from the time
            We first notify You about such changes via email or/and
            notifications while using the PDF Pro Software and/or other
            reasonable means. Your continued use of the PDF Pro Software
            following changes to this EULA after the effective date of a revised
            version thereof constitutes Your expressed acceptance of, and
            agreement to be bound by, this EULA as in force from time to time.
            If You do not accept modified EULA in part or full, You must
            terminate Your account and stop using the PDF Pro Software before
            the change takes effect.
          </Paragraphs>
          <Paragraphs>
            <strong>10.8) Entire agreement</strong>. This EULA, along with the
            terms and conditions incorporated herein by reference (i.e., the PDF
            Pro Terms of Service (available at <Link to="/licensing">EULA</Link>
            ), the PDF Pro Privacy Policy (available at{" "}
            <Link t="/privacy">Privacy Policy</Link> ) and the PDF Pro Data
            Processing Agreement (available at [
            <Link to="/cookies">Cookie Policy</Link>) constitute the entire
            agreement between the Parties with respect to its subject matter and
            it supersedes all prior or contemporaneous agreements concerning
            such matter.
          </Paragraphs>
        </Text>
      </Container>
      <NewFooter />
    </>
  )
}

export default Licensing
